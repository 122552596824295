import React, { useState, useEffect } from "react";
import { MDBNavbar, MDBContainer } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTiktok,
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  DownloadOutlined,
  BankOutlined,
  ClusterOutlined,
  AimOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
import {
  faBars,
  faPhoneAlt,
  faCameraRetro,
  faHandshakeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Drawer, Image, Button, Dropdown, Menu, Tooltip } from "antd";
import { Link } from "react-router-dom";
import logoGob from "../img/sobreNosotros/letrasAzules.webp";
import "./CustomNavbar.css";

const CustomNavbar = () => {
  const [bgColor, setBgColor] = useState("transparent");
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const isTop = scrollTop === 0;
      setBgColor(isTop || scrollTop < 600 ? "transparent" : "light");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://wa.me/+50247902524"
          className="dropdown-item"
        >
          <FontAwesomeIcon icon={faPhoneAlt} className="reception-icon" />
          Recepción
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://wa.me/+50240403573"
          className="dropdown-item"
        >
          <FontAwesomeIcon icon={faCameraRetro} className="camera-icon" />
          Comunicación Social
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
          className="dropdown-item"
        >
          <FontAwesomeIcon
            icon={faHandshakeAlt}
            className="public-services-icon"
          />
          Servicios Públicos
        </a>
      ),
    },
  ];

  const menu1 = <Menu items={items} />;

  const items2 = [
    {
      key: "4",
      label: (
        <Link to="/" className="dropdown-item" onClick={toggleDrawer}>
          <BankOutlined className="dropdown-icon" />
          Alcaldía
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link to="/" className="dropdown-item" onClick={toggleDrawer}>
          <ClusterOutlined className="dropdown-icon" />
          Organigrama
        </Link>
      ),
    },
    {
      key: "6",
      label: (
        <Link to="/" className="dropdown-item" onClick={toggleDrawer}>
          <AimOutlined className="dropdown-icon" />
          Misión y Visión
        </Link>
      ),
    },
    {
      key: "7",
      label: (
        <Link to="/" className="dropdown-item" onClick={toggleDrawer}>
          <BranchesOutlined className="dropdown-icon" />
          Dependencias
        </Link>
      ),
    },
  ];

  const menu2 = <Menu items={items2} />;

  return (
    <MDBNavbar fixed="top" light={bgColor === "light"} bgColor={bgColor}>
      <MDBContainer fluid>
        <div className="navbar-content">
          <div className="left-section">
            <div className="social-icons">
              <a href="https://www.facebook.com/tumuniclm">
                <FontAwesomeIcon icon={faFacebook} className="icon fb" />
              </a>
              <a href="https://www.tiktok.com/@tumuniclm">
                <FontAwesomeIcon icon={faTiktok} className="icon tk" />
              </a>
              <a href="https://www.instagram.com/tumuniclm?igsh=NWQ3dnZneGxmOThm">
                <FontAwesomeIcon icon={faInstagram} className="icon ins" />
              </a>
              <a href="https://www.youtube.com/@tuMuniCLM">
                <FontAwesomeIcon icon={faYoutube} className="icon yt" />
              </a>
            </div>
            <span className="click-text">
              Haz click en nuestras redes sociales
            </span>
          </div>
          <a onClick={toggleDrawer} className="menu-icon">
            <FontAwesomeIcon icon={faBars} />
          </a>
        </div>
      </MDBContainer>

      <Drawer
        title={
          <div style={{ textAlign: "center", width: "100%" }}>
            Menú informativo
          </div>
        }
        placement="right"
        onClose={toggleDrawer}
        visible={drawerOpen}
        zIndex={9999} // Asegura que el Drawer esté encima de todo
      >
        <div
          className="opcionesNav"
          style={{
            width: "100%",
            marginTop: "1em",
          }}
        >
          <Dropdown
            overlay={menu1}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button type="primary" className="botonMenu">
              <FontAwesomeIcon icon={faWhatsapp} className="icon wh" />
              Contactános
            </Button>
          </Dropdown>

          <Dropdown
            overlay={menu2}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button block className="botonMenu">
              Sobre Nosotros
            </Button>
          </Dropdown>

          <a href="https://forms.gle/ctLo7sjSrXkLsLud9">
            <Button block className="botonMenu">
              Línea de Denuncias
            </Button>
          </a>
          <Tooltip title="Haz click aquí para ver el sitio">
            <a href="https://municoncepcionlasminas.gob.gt/index.php/informacion-publica">
              <Button block className="botonMenu">
                Ver información Pública
              </Button>
            </a>
          </Tooltip>

          <Button
            type="primary"
            icon={<DownloadOutlined />}
            className="botonMenu"
          >
            <a href="https://drive.google.com/drive/folders/1f7tHjSSPe4LEH3Bt70UQotyQjCKZZK-x?usp=sharing">
              Descargar Recursos
            </a>
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Image width={300} src={logoGob} />
        </div>
      </Drawer>
    </MDBNavbar>
  );
};

export default CustomNavbar;
